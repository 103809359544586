/* custom vars */
$custom-font-size: 20px;
span.inline-block {
  display: inline-block;
}
h2 .img-token {
  height: 75px;
  width: 75px;
  padding: 10px 0;
}
h3 .img-token {
  height: 60px;
  width: 60px;
  padding: 10px 0;
}
.img-token {
  width: 25px;
  height: 25px;
}
.toc-entry .img-token {
  width: 25px;
  height: 25px;
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: 0.8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

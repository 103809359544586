@import 'variables';
@import 'bootstrap/scss/bootstrap';

.content {
  font-size: $custom-font-size;
}
ul.post-list {
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  padding: 0;
  margin: 0;
}
.svg-icon {
  width: 22px;
  height: 23px;
  display: inline-block;
  fill: #828282;
  padding-right: 5px;
  vertical-align: text-top;
}
blockquote p {
  padding: 15px;
  background: #eee;
  border-radius: 5px;
}






// --------------------------------------BRACKET CSS STUFF-------------------------------------------

// Tournament Format items
.tournament {
  display: flex;
  flex-direction: row;
}
.tournament-header {
  text-align: center;
  font-weight: bold;
}
.tournament .round {
  font-size: .75em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  list-style: none;
  padding: 0;
}
.tournament .round .spacer {
  flex-grow: 3;
}
.tournament .round .spacer:first-child,
.tournament .round .spacer:last-child {
  flex-grow: 1.5;
}
.tournament .reseedspace {
  width: 10px;
  display: flex;
}


// used for items where we show the time entry.  Allows us to configure time size without affecting other stuff
.time-entry {
  font-size:1em;
  padding-left: 0px;
  text-align:center;
}

// used for a spacer inside a bracket (should have a right border)
.tournament .round .intrabracket {
   border-right: 1px solid #aaa;
}

@media screen and (max-width: 400px) {
  //div.example {
  //  font-size: 30px;
  //}
    .team-entry {
      text-align:left;
      padding-left: 0px;
      font-size: 0.6em;
    }
    .score-entry {
      padding-top: 6.5px;
      float:right;
    }
}

@media screen and (max-width: 600px) and (min-width: 401px) {
  //div.example {
  //  font-size: 30px;
  //}
    .team-entry {
      text-align:left;
      padding-left: 0px;
      font-size: 0.8em;
    }
    .score-entry {
      padding-top: 5px;
      float:right;
    }
}

@media (prefers-color-scheme: dark) {
    body { background: #333; color: white; }
}

@media screen and (min-width: 601px) {
  //div.example {
  //  font-size: 30px;
  //}
    .team-entry {
      text-align:left;
      padding-left: 5px;
      font-size: 1em;
    }
    .score-entry {
      padding-top: 2px;
      float:right;
    }
}
// Used to signify this is where we are going to put a team and score
//.team-entry {
//  text-align:left;
//  padding-left: 5px;
//  font-size: 1em;
//}
//.score-entry {
//  padding-top: 4px;
//  float:right;
//}

// Add to the ul of the winner of the bracket to bold everything
.winner {
  font-weight:bold;
}

// This team appears above the bracket (bottom border, no right border)
.top-team {
  border-bottom: 1px solid #aaa;
  padding-right: 2px;
}

// This team appears on the lower line of the bracket (bottom & right border)
.bottom-team {
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding-right: 1px;
}

// Used specifically for NFC/AFC identifiers to stand out
li.conf-id {
  font-size: 2em;
  font-weight:bold;
}

